import React, { Fragment } from 'react'
import {Button, Card, Form, Input} from 'antd'
import { Link } from 'react-router-dom'
const {Item} = Form
export const Login = ({
  onSubmit
}) => {

  return (
  <Fragment>
    <Card style={{margin: "20%"}}>
      <Form onFinish={onSubmit}>
        <Item name="email" label = "Email">
          <Input type="email"/>
        </Item>

        <Item name="password" label= "Password">
          <Input.Password/>
        </Item>
        <div style={{display:"flex", justifyContent:"space-between"}}> <Button htmlType="submit">Login</Button>
          <Link to="/register">Wants to Sign Up?</Link></div>
      </Form>
    </Card>
  </Fragment> )
}
