import CollabClient from "@pdftron/collab-client";
import WebViewer, { getInstance } from "@pdftron/webviewer";
import { Select } from "antd";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { SERVER_CONFIG  } from "./config";
const url = SERVER_CONFIG.socketWs;
const initialDoc = "https://pdftron.s3.amazonaws.com/downloads/pl/webviewer-demo.pdf"
const connection = new WebSocket(url);

export const WebViewerComponent = ({ email, password, userNotFound }) => {
  const selectedUser = useRef(null);
  const [allUsers, setAllUsers] = useState([]);
  const viewer = useRef(null);
  const clientRef = useRef(null);
  const defaultRef = useRef(null);
  const instanceRef = useRef(null);
  let annotManager = null;
  const DOCUMENT_ID = "webviewer-demo-1";

  const nameList = [
    "Andy",
    "Andrew",
    "Logan",
    "Justin",
    "Matt",
    "Sardor",
    "Zhijie",
    "James",
    "Kristian",
    "Mary",
    "Patricia",
    "Jennifer",
    "Linda",
    "David",
    "Joseph",
    "Thomas",
    "Naman",
    "Nancy",
    "Sandra"
  ];
  const serializer = new XMLSerializer();

  useEffect(() => {
    defaultRef.current = viewer.current;
    initializeWebViewer();
  }, []);

  useEffect(() => {
    getAllUsers(email);
  }, [email]);

  const initializeWebViewer = () => {
    WebViewer(
      {
        path: "/webviewer/lib", // path to the PDFTron 'lib' folder
        initialDoc: initialDoc,
        documentXFDFRetriever: async () => {
          const rows = await loadXfdfStrings(DOCUMENT_ID, selectedUser.current);
          if (rows) return JSON.parse(rows).map(row => row.xfdfString);
        }
      },
      viewer.current
    ).then(async instance => {
      instanceRef.current = instance;
      const collabClient = new CollabClient({
        instance,
        url: SERVER_CONFIG.socketUrl,
        subscriptionUrl: `${SERVER_CONFIG.socketWs}/subscribe`
      });

      const user = await collabClient.loginWithPassword(email, password);
      console.info(" user", user)
      if (!user) {
        userNotFound();
      }
      clientRef.current = user;
      // Instance is ready here
      instance.openElements(["leftPanel"]);
      annotManager = instance.docViewer.getAnnotationManager();
      // Assign a random name to client
      annotManager.setCurrentUser(
        nameList[Math.floor(Math.random() * nameList.length)]
      );

      annotManager.on("annotationChanged", async e => {
        // If annotation change is from import, return
        if (e.imported) {
          return;
        }

        const xfdfString = await annotManager.exportAnnotCommand();
        // Parse xfdfString to separate multiple annotation changes to individual annotation change
        const parser = new DOMParser();
        const commandData = parser.parseFromString(xfdfString, "text/xml");
        const addedAnnots = commandData.getElementsByTagName("add")[0];
        const modifiedAnnots = commandData.getElementsByTagName("modify")[0];
        const deletedAnnots = commandData.getElementsByTagName("delete")[0];

        // List of added annotations
        addedAnnots.childNodes.forEach(child => {
          sendAnnotationChange(child, "add");
        });

        // List of modified annotations
        modifiedAnnots.childNodes.forEach(child => {
          sendAnnotationChange(child, "modify");
        });

        // List of deleted annotations
        deletedAnnots.childNodes.forEach(child => {
          sendAnnotationChange(child, "delete");
        });
      });

      connection.onmessage = async message => {
        const annotation = JSON.parse(message.data);
        const annotations = await annotManager.importAnnotCommand(
          annotation.xfdfString
        );
        await annotManager.drawAnnotationsFromList(annotations);
      };

      connection.onerror = async message => {
        console.error("error occured");
      };
    });
  };
  // helper function to send annotation changes to WebSocket server
  const sendAnnotationChange = (annotation, action) => {
    if (annotation.nodeType !== annotation.TEXT_NODE) {
      const annotationString = serializer.serializeToString(annotation);
      connection.send(
        JSON.stringify({
          documentId: DOCUMENT_ID,
          annotationId: annotation.getAttribute("name"),
          xfdfString: convertToXfdf(annotationString, action),
          client: clientRef.current.user
        })
      );
    }
  };

  const convertToXfdf = (changedAnnotation, action) => {
    let xfdfString = `<?xml version="1.0" encoding="UTF-8" ?><xfdf xmlns="http://ns.adobe.com/xfdf/" xml:space="preserve"><fields />`;
    if (action === "add") {
      xfdfString += `<add>${changedAnnotation}</add><modify /><delete />`;
    } else if (action === "modify") {
      xfdfString += `<add /><modify>${changedAnnotation}</modify><delete />`;
    } else if (action === "delete") {
      xfdfString += `<add /><modify /><delete>${changedAnnotation}</delete>`;
    }
    xfdfString += `</xfdf>`;
    return xfdfString;
  };

  const loadXfdfStrings = (documentId, userId) => {
    return new Promise((resolve, reject) => {
      fetch(
        `${SERVER_CONFIG.apiUrl}/server/annotationHandler?documentId=${documentId}&userId=${userId}`,
        {
          method: "GET"
        }
      ).then(res => {
        if (res.status < 400) {
          res.text().then(xfdfStrings => {
            resolve(xfdfStrings);
          });
        } else {
          reject(res);
        }
      });
    });
  };

  const getAllUsers = async email => {
    const users = new Promise((resolve, reject) => {
      fetch(`${SERVER_CONFIG.apiUrl}/server/getAll?email=${email}`, {
        method: "GET"
      }).then(res => {
        if (res.status < 400) {
          res.text().then(xfdfStrings => {
            resolve(xfdfStrings);
          });
        } else {
          reject(res);
        }
        
      })
    });
   const rows =  await users;
   setAllUsers(JSON.parse(rows) || []);
  };

  const onUserSelect = async value => {
    selectedUser.current = value;
    const instance = getInstance();
    instance.closeDocument().then(function() {
      instance.loadDocument(
        initialDoc
      );
    });
  };

  return (
    <Fragment>
      <Select placeholder="Select User" onSelect={onUserSelect}>
        {allUsers.map(({ id, email }, index) => (
          <Select.Option
            style={{ textTransform: "Captilize" }}
            value={id}
            key={`${index}-${id}`}
          >
            {email}
          </Select.Option>
        ))}
      </Select>
      <div className="webviewer" ref={viewer}></div>
    </Fragment>
  );
};
