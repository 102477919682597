import React, { useState } from 'react';
import { Route, Routes, BrowserRouter} from 'react-router-dom'
import './App.css';
import 'antd/dist/antd.css'
import { Register } from './register'; 
import { WebViewerComponent } from './webViewer';
import { Login } from './login';

const initialState = {
  email: null,
  password: null,
  isAuthenticated: false
}
const App = () => {
  const [client, setClient] = useState(null)

  const [state, setState] = useState(initialState);

  const onSubmit = ({email, password, ...rest}) => {
    console.info(" values are", rest)
    setState({
      email: email,
      password: password,
      isAuthenticated: true
    })
  }

  const userNotFound = () => {
    setState(initialState)
    alert("User not found")
  }

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
        <Route path="/register" element={<Register/>}/>
        <Route path="/" element={state.isAuthenticated ? <WebViewerComponent {...state} userNotFound={userNotFound}/>: <Login onSubmit={onSubmit}/>}/>
        </Routes>
      </BrowserRouter>
      
    </div>
  );
};

export default App;
