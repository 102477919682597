import React, { Fragment } from "react";
import { Button, Card, Form, Input } from "antd";
import axios from "axios";
import { Link } from "react-router-dom";
import { SERVER_CONFIG  } from "./config";

const { Item } = Form;
export const Register = () => {
  const onHandle = async values => {
    console.info(" values are", values)
    await axios.post(`${SERVER_CONFIG.apiUrl}/server/createUser`, values, { withCredentials: true });
    window.location.replace('/')
  };

  return (
    <Fragment>
      <Card style={{ margin: "20%" }}>
        <Form onFinish={onHandle}>
          <Item name="userName" label="Username">
            <Input type="text" />
          </Item>
          <Item name="email" label="Email">
            <Input type="email" />
          </Item>

          <Item name="password" label="Password">
            <Input.Password />
          </Item>
         <div style={{display:"flex", justifyContent:"space-between"}}> <Button htmlType="submit">Register</Button>
          <Link to="/">Wants to log In?</Link></div>
        </Form>
      </Card>
    </Fragment>
  );
};
