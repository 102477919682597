export const SERVER_CONFIG = {
    apiHost: "api.pdftron.ansysica.io",
    socketHost: "socket.pdftron.ansysica.io",
    apiUrl: "https://api.pdftron.ansysica.io",
    socketUrl: "https://socket.pdftron.ansysica.io",
    socketWs: "wss://socket.pdftron.ansysica.io"
}

//local
// export const SERVER_CONFIG = {
//     apiHost: "localhost",
//     socketHost: "localhost:8080",
//     apiUrl: "http://localhost:8080",
//     socketUrl: "http://localhost:8080",
// }